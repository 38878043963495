<template>
  <v-dialog v-model="dialog" max-width="600">
    <v-card>
      <v-toolbar dense :color="hexTints(area.cor,0.95)">
        <v-btn class="ms-0 me-2" icon dark small @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="ms-1 ps-0 fs-12pt f-lato fw-600">Editar Certificação</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="mt-3">
        <p class="p-0 m-0 mb-1">Certificação</p>
        <v-textarea class="mb-0 f-lato fs-11pt" v-model="texto" @update:error="erroUpdate" label="Digite texto da certificação" :rules="rules" auto-grow solo rows="8"></v-textarea>
        <v-divider class="mt-0"></v-divider>
        <div class="text-center">
          <v-btn color="" small @click="dialog = false">
            Cancelar
          </v-btn>
          <v-btn class="ms-4" color="success" small :disabled="!erroValor" @click="adicionar">
            Alterar
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import { rdb, ge } from '@/firebasedb.js'

export default {
  name: "textAreaDialogEdit",
  components: {
  },
  props: {
    mensagem: { default: "", type: String },
    color: { default: "#000000", type: String },
    fieldTitle: { default: "", type: String },
    acao: { default: "Adicionar", type: String },
    area: { default: null, type: Object },
    uc: { default: null, type: Object },
  },
  computed: { },
  data(){
    return {
      nivelSel: "lembrar",
      texto: "",
      msgProp: "",
      dialog: false,
      rules: [
        value => !!value || 'Texto obrigatório!',
        value => (value && value.length >= 10) || 'Mínimo 10 caracteres',
      ],
      erroValor: false,
      meta: {
        ativada: true,
        nivel: "lembrar",
        banco: "",
        bancoCodigo: "",
        createdAt: "",
        emProducao: true,
        id: "xxx",
        metaCodigo: "",
        nova: true,
        texto: "",
        ucID: "xxx",
        ucNome: ""
      },
    }
  },
  watch: {
    mensagem(to, from) {
      var self = this;
      this.build();
    },
    dialog(to, from) {
      var self = this;
    },
  },
  mounted: function() {
    var self = this;
    this.build();
  },
  methods:{

    build() {
      var self = this;
    },

    show(certificacao) {
      console.log("certificacao",certificacao);
      this.texto = "";
      if(certificacao != undefined) {
        this.texto = certificacao.texto;
      }
      this.erroValor = true;
      this.refresh++;
      this.dialog = true;
      this.$forceUpdate();
    },

    erroUpdate(value) {
      this.erroValor = !value;
      console.log(value);
    },

    adicionar() {
      this.dialog = false;
      this.$emit('changeValue',this.texto);
    }

  }
}

</script>

<style scoped>

</style>

<template>
  <v-container fluid class="m-0 p-0">
    <v-row dense class="mt-1" >
      <v-col cols="12" class="">
        <v-card class="pb-4" v-bg:B>
          <v-card class="m-0 p-0" flat :color="hexTints(area.cor,0.95)">
            <v-container fluid class="">
              <v-row dense align="center" justify="space-between" class="">
                <v-col cols="auto" class="m-0 p-0">
                  <v-avatar dark :color="hexTints(area.cor,0.88)" size="32" class="m-0 p-0">
                    <i v-i:duo#note#22 v-cHex="area.cor" class="btn-icon-left ps-1"></i>
                  </v-avatar>
                </v-col>
                <v-col cols="10" class="m-0 p-0">
                  <div class="mt-0 line-height-1 f-lato fw-600 fs-10pt text-truncate">
                    Certificação
                  </div>
                </v-col>
                <v-col cols="1" class="m-0 p-0 text-right pe-1" v-if="!noedit && blocked">
                  <v-badge bordered :color="hexTints(area.cor,.3)" icon="mdi-lock" overlap>
                    <v-btn color="red" class="m-0 p-0" small icon elevation="2" :style="'background-color:'+hexTints(area.cor,.7)" @click="$refs.txtareadialogeditref.show(certificacao)">
                      <i v-i:duo#pencil#20 v-cHex="hexShades(area.cor,0.2)" class="btn-icon-left ps-1"></i>
                    </v-btn>
                  </v-badge>
                </v-col>
              </v-row>
            </v-container>
          </v-card>

          <v-container fluid class="">
            <v-row dense>
              <v-col cols="12" class="mt-0 p-1 text-justify" v-if="certificacao.texto == '' || primeira">
                <v-alert type="info" v-c:P :color="hexTints(area.cor,0.95)" class="m-0 p-2 mt-2 fs-10pt">
                  Aguardando 1a. atualização
                </v-alert>
              </v-col>
              <v-col cols="12" class="mt-0 p-1 text-justify" v-if="certificacao.texto != '' && !primeira">
                <p class="m-0 p-0 text-dark mt-0 fs-10pt f-raleway fw-400">
                  {{certificacao.texto}}
                </p>
              </v-col>
            </v-row>
            <v-row v-if="certificacao.texto != '' && !primeira">
              <v-col cols="12" class="m-0 p-0 mt-n2">
                <div class="m-0 me-3 mt-0 p-0" v-if="certificacao.createdAt != undefined" :style="'color:'+hexShades(area.cor,.6)">
                  <div class="right f-raleway fs-8pt fw-300">atualizado {{$moment(certificacao.createdAt).fromNow()}}</div>
                  <div v-i:duo#clock#12 class="right me-1 mt-n2 pt-0" :style="'color:'+hexShades(area.cor,.6)"></div>
                </div>
              </v-col>
            </v-row>
            <v-row v-if="!primeira">
              <v-col cols="12" class="m-0 p-0 mt-1">
                <feedcontroler ref="fcontroler" :area="area" :uc="ucSel" :color="area.cor" :rdbPath="'/curriculo/socialData/certificacao/'+ucSel.id" :key="refresh"/>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <textareadialogedit ref="txtareadialogeditref" :uc="ucSel" :area="area" @changeValue="editar" />
  </v-container>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import textareadialogedit from '@/views/cursos/planosv2/certificacao/textAreaDialogEdit.vue'
import feedcontroler from '@/views/feedsComponent/feedControler.vue'

export default {
  name: "certificacaoview",
  components: { textareadialogedit, feedcontroler },
  props: {
    noedit: { default: false, type: Boolean },
    parse: { default: false, type: Boolean },
    area: { default: null, type: Object },
    ucSel: { default: null, type: Object },
  },
  computed: {
    blocked() {
      if(this.area != null) {
        var gestores = ["Gestor(a) de Área","Assessor(a) de Área","Coordenador(a) Regional"]
        if(this.isUserAdmin) return true;
        if(gestores.indexOf(this.userLog.cargo) != -1 && this.userLog.areasIC[this.area.id] != undefined) {
          return true;
        }
      }
      return false;
    }
  },
  data(){
    return {
      loading: false,
      refresh: 0,
      uc: null,
      certificacao: { texto: "", createdAt: "" },
      primeira: false,
    }
  },
  watch: {
    ucSel(to, from) {
      var self = this;
      //console.log("ementaView change uc");
      this.build();
    },
  },
  mounted: function() {
    var self = this;
    //self.loading = true;
    //rdb.ref('').on('value',function(snapshot) {
    //  self.algo = snapshot.val();
    //  console.log("self.algo",self.algo);
    self.build();
    //});
  },
  methods:{

    build() {
      var self = this;
      self.uc = this.ucSel;
      rdb.ref('/curriculo/ucs/'+this.uc.id+'/certificacao').on('value',function(snapshot) {
        var cert = snapshot.val();
        self.certificacao = { texto: "", createdAt: "" };
        self.primeira = false;
        if(cert != null) {
          if(typeof cert == 'string') {
            self.certificacao = { texto: cert, createdAt: self.uc.createdAt };
            self.primeira = true;
          } else {
            self.certificacao = cert;
          }
        }
        console.log("self.certificacao",self.certificacao);
        self.refresh++;
      });
    },

    editar(texto) {
      console.log("editar",texto);
      var elem = {
        createdAt: moment().format(),
        texto: texto
      }
      rdb.ref("/curriculo/ucs/"+this.uc.id+'/certificacao').set(elem);
      this.build();
      this.refresh++;
      this.$forceUpdate();
    }
  }
}
</script>

<style scoped>
</style>
